/*
 * @Author       : Derek.K
 * @Date         : 2022-02-14 15:34:03
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-14 15:38:17
 * @Description  : N/A
 */
export const CardType = {
  Default: "TXT",
  TXT: "TXT",
  IMGTXT: "IMGTXT",
};

export const ExtDataSrcType = {
  Script: "SCRIPT",
  Firestore: "FIRESTORE",
};
