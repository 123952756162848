import { createSlice } from "@reduxjs/toolkit";

import { CardType } from "../utils/EnumData";

const initialWordDataState = {
  wordsJSON: [],
  displayWords: [],
  cardType: CardType.Default,
  cardInner: 0,
};

const wordDataSlice = createSlice({
  name: "wordData",
  initialState: initialWordDataState,
  reducers: {
    setCardType(state, action) {
      state.cardType = action.payload;
    },
    saveFetchWords(state, action) {
      state.wordsJSON = action.payload;
    },
    setDisplayWords(state, action) {
      state.displayWords = action.payload;
    },
    setCardInner(state, action) {
      state.cardInner = action.payload;
    },
  },
});

export const wordDataActions = wordDataSlice.actions;

export default wordDataSlice.reducer;
