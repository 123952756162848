/*
 * @Author       : Derek.K
 * @Date         : 2021-11-12 14:36:47
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-21 16:13:11
 * @Description  : N/A
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

//const baseFontSize = 9;
const baseFontSize = 24;
const baseFontSize_Chn = 12;

const WordCard = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "85vw",
  //maxWidth: "90vh",
  // height: "50vh",
  // width: "60vmin",
  //minWidth: "60vmin",
  //maxWidth: "76vmin",
  //minHeight: "40vmin",
  padding: theme.spacing(0, 2),
  overflow: "hidden",
  fontFamily: "'Didact Gothic', sans-serif",
  backgroundColor: theme.palette.background.default,
  background: theme.palette.background.default,
}));

var convertToShow = (dataArray, val) => {
  if (dataArray[val] === undefined) return ["", ""];
  return [dataArray[val].word, dataArray[val].imgLink];
};

var calFontSize = (word) => {
  if (word === undefined) return baseFontSize;
  var regex4 = /[0-9A-Z]+/i;
  var match4 = word.match(regex4);
  if (match4 !== null) {
    //Alphabet
    if (word.length <= 3) return baseFontSize;
    return Math.max((baseFontSize * 3) / word.length + 2, 6);
  } else {
    if (word.length <= 2) return baseFontSize_Chn;
    return Math.max((baseFontSize * 2) / word.length + 2, 6);
  }
};

var useGetDisplayData = (wordDataArray, cardInner) => {
  const [outword, setOutWord] = useState("");
  const [outImg, setOutImg] = useState("");

  useEffect(() => {
    let [oWord, oImg] = convertToShow(wordDataArray, cardInner);
    setOutWord(oWord);
    setOutImg(oImg);

    return () => {};
  }, [wordDataArray, cardInner]);

  return [outword, outImg];
};

export default function FundCard() {
  const wordDataArray = useSelector((state) => state.wordData.displayWords);
  const cardInner = useSelector((state) => state.wordData.cardInner);

  const [displayWord, displayImg] = useGetDisplayData(wordDataArray, cardInner);

  return (
    <>
      <WordCard variant="0">
        <CardMedia
          sx={{ flexGrow: "1", maxWidth: "30vw", maxHeight: "30vw" }}
          component="img"
          image={displayImg}
          alt=""
        />
        <CardContent
          sx={{
            display: "flex",
            flexGrow: "1",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: calFontSize(displayWord) + "vw" }}
            component="p"
            color="text.secondary"
          >
            {displayWord}
          </Typography>
        </CardContent>
      </WordCard>
    </>
  );
}
