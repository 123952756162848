/*
 * @Author       : Derek.K
 * @Date         : 2021-11-15 16:36:50
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-25 17:42:03
 * @Description  : N/A
 */
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { menuDataActions } from "../store/menuData";
import { wordDataActions } from "../store/wordData";

const ITEM_HEIGHT = 48;

const WordsTypeFAB = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  position: "fixed",
  left: theme.spacing(1),
  top: theme.spacing(1),
}));

const TopFABArea = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  margin: theme.spacing(1),
  position: "fixed",
  display: "flex",
  fontSize: "small",
  justifyContent: "space-between",
  alignItems: "center",
  top: theme.spacing(1),
}));

const RandomBtn = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  margin: theme.spacing(0, 0.5),
  fontSize: "small",
}));

const ImageBtn = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  margin: theme.spacing(0, 0.5),
  fontSize: "small",
}));

const ArrowBtn = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  //backgroundColor: theme.palette.background.default,
  fontSize: "6rem",
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  position: "fixed",
  //right: theme.spacing(1),
}));

export default function FABMenu(props) {
  const dispatch = useDispatch();

  /* For Word Type Menu */
  const showMenu = useSelector((state) => state.menuData.showMenu);
  const menuOpt = useSelector((state) => state.menuData.menuOpt);
  const currMenuChoice = useSelector((state) => state.menuData.currPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setMenuWordType = (choice) => {
    dispatch(menuDataActions.setCurrPage(choice));
    dispatch(wordDataActions.setCardInner(0));
    handleClose();
  };

  /* For Random Button */
  const showRandBtn = useSelector((state) => state.menuData.showRandBtn);
  const rand = useSelector((state) => state.menuData.rand);
  const cardInner = useSelector((state) => state.wordData.cardInner);

  /* For Arrow Button */
  const wordsLen = useSelector((state) => state.wordData.displayWords).length;

  var changeCardInner = (cardInner, direction) => {
    let diff = direction === "N" ? +1 : -1;
    let output = cardInner + diff;
    return output;
  };

  /* For Display Image Button */
  const showImgBtn = useSelector((state) => state.menuData.showImgBtn);
  const displayCardImage = useSelector(
    (state) => state.menuData.displayCardImage
  );

  return (
    <>
      {showMenu ? (
        <WordsTypeFAB
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <DehazeIcon />
        </WordsTypeFAB>
      ) : (
        <></>
      )}
      {showMenu ? (
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {menuOpt.map((option) => (
            <MenuItem
              key={option}
              selected={option === currMenuChoice}
              onClick={() => setMenuWordType(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <></>
      )}
      <TopFABArea>
        {showRandBtn ? (
          <RandomBtn
            variant={rand ? "contained" : "outlined"}
            onClick={() => {
              //setRand(!rand);
              dispatch(menuDataActions.toggleRandam());
              //setCardInner(0);
              dispatch(wordDataActions.setCardInner(0));
            }}
          >
            Random
          </RandomBtn>
        ) : (
          <></>
        )}
        {showImgBtn ? (
          <ImageBtn
            variant={displayCardImage ? "contained" : "outlined"}
            onClick={() => {
              dispatch(menuDataActions.toggleDisplayCardImg());
            }}
          >
            Image
          </ImageBtn>
        ) : (
          <></>
        )}
      </TopFABArea>
      {true ? (
        <>
          <ArrowBtn
            onClick={() =>
              dispatch(
                wordDataActions.setCardInner(changeCardInner(cardInner, "P"))
              )
            }
            disabled={cardInner === 0}
            sx={{ left: "0px" }}
          >
            <ArrowBackIosNewIcon fontSize="inherit" />
          </ArrowBtn>
          <ArrowBtn
            onClick={() =>
              dispatch(
                wordDataActions.setCardInner(changeCardInner(cardInner, "N"))
              )
            }
            disabled={cardInner === wordsLen - 1}
            sx={{ right: "0px" }}
          >
            <ArrowForwardIosIcon fontSize="inherit" />
          </ArrowBtn>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
