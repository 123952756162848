/*
 * @Author       : Derek.K
 * @Date         : 2022-02-18 12:26:31
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-18 12:27:54
 * @Description  : N/A
 */
import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Link } from "@mui/material";

const CopyrightArea = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  //backgroundColor: theme.palette.background.default,
  fontSize: "small",
  color: "#383838",
  position: "fixed",
  bottom: theme.spacing(0.2),
  right: theme.spacing(0.5),
}));

function App() {
  return (
    <CopyrightArea>
      ©2022{" "}
      <Link color="inherit" href="mailto:admin@mizz.app">
        Mizz.app
      </Link>
      , v1.1.0
    </CopyrightArea>
  );
}

export default App;
