import { configureStore } from "@reduxjs/toolkit";

import wordDataReducer from "./wordData";
import menuDataReducer from "./menuData";

const store = configureStore({
  reducer: { menuData: menuDataReducer, wordData: wordDataReducer },
});

export default store;
