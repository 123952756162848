/*
 * @Author       : Derek.K
 * @Date         : 2021-11-15 15:00:12
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-14 12:33:25
 * @Description  : N/A
 */

export const Welcome = [{ Word: "Welcome" }];

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
// const alphabet = alpha.map((x) => String.fromCharCode(x));
//export const Uppercase = alpha.map((x) => String.fromCharCode(x));
export const Uppercase = alpha.map((x) =>
  Object.assign({}, { Word: String.fromCharCode(x) })
);
//export const Lowercase = alpha.map((x) => String.fromCharCode(x + 32));
export const Lowercase = alpha.map((x) =>
  Object.assign({}, { Word: String.fromCharCode(x + 32) })
);

//export const Numbers = Array.from(Array(10)).map((e, i) => i.toString());
export const Numbers = Array.from(Array(10)).map((e, i) =>
  Object.assign({}, { Word: i.toString() })
);
