/*
 * @Author       : Derek.K
 * @Date         : 2021-11-12 14:36:47
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-21 16:08:26
 * @Description  : N/A
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const baseFontSize = 36;
const baseFontSize_Chn = 28;

const WordCard = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "85vh",
  maxWidth: "85vw",
  // height: "50vh",
  // width: "60vmin",
  //minWidth: "60vmin",
  //maxWidth: "76vmin",
  //minHeight: "40vmin",
  padding: theme.spacing(0, 2),
  overflow: "hidden",
  fontFamily: "'Didact Gothic', sans-serif",
  backgroundColor: theme.palette.background.default,
  background: theme.palette.background.default,
}));

var convertToShow = (dataArray, val) => {
  if (dataArray[val] === undefined) return "";
  let word = dataArray[val].word;
  return word;
};

var calFontSize = (word) => {
  if (word === undefined) return baseFontSize;
  var regex4 = /[0-9A-Z]+/i;
  var match4 = word.match(regex4);
  if (match4 !== null) {
    //Alphabet
    if (word.length <= 3) return baseFontSize;
    return Math.max((baseFontSize * 3) / word.length + 2, 5);
  } else {
    if (word.length <= 2) return baseFontSize_Chn;
    return Math.max((baseFontSize * 2) / word.length + 2, 8);
  }
};

var useGetWordList = (wordDataArray, cardInner) => {
  const [output, setOutput] = useState("0");

  useEffect(() => {
    setOutput(convertToShow(wordDataArray, cardInner));
    return () => {};
  }, [wordDataArray, cardInner]);

  return [output];
};

export default function FundCard() {
  const wordDataArray = useSelector((state) => state.wordData.displayWords);
  const cardInner = useSelector((state) => state.wordData.cardInner);

  const [displayWord] = useGetWordList(wordDataArray, cardInner);

  return (
    <>
      <WordCard style={{ fontSize: calFontSize(displayWord) + "vw" }}>
        {displayWord}
      </WordCard>
    </>
  );
}
