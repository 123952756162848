/*
 * @Author       : Derek.K
 * @Date         : 2021-11-12 12:20:59
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-03-10 15:11:01
 * @Description  : N/A
 */
//import "./App.css";
import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import CardSelector from "./components/CardSelector";
import FABComp from "./components/FABComp";
import CopyrightComp from "./components/CopyrightComp";

import { sleep } from "./utils/Utils";
import { Welcome, Lowercase, Uppercase, Numbers } from "./utils/DataUtils";
import { ExtDataSrcType, CardType } from "./utils/EnumData";

import { collection, getDocs, query } from "firebase/firestore";
import { db } from "./utils/init-firebase";

import { menuDataActions } from "./store/menuData";
import { wordDataActions } from "./store/wordData";

// eslint-disable-next-line
const darkTheme = createTheme({ palette: { mode: "dark" } });
// eslint-disable-next-line
const lightTheme = createTheme({ palette: { mode: "light" } });

const MainArea = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  //minHeight: "100vh", // before rotate
  height: "100vmin",
  width: "100vmax",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  //WebkitTransform: "rotate(90deg)",
  backgroundColor: theme.palette.background.default,
}));

let isDev = true;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  isDev = true;
} else {
  isDev = false;
}

const ExtDataSrc = ExtDataSrcType.Firestore;

function App() {
  const dispatch = useDispatch();

  const cardType = useSelector((state) => state.wordData.cardType);
  const currMenuChoice = useSelector((state) => state.menuData.currPage);
  const showDefaultMenuOpt = useSelector(
    (state) => state.menuData.showDefaultMenuOpt
  );
  const rand = useSelector((state) => state.menuData.rand);

  //從外部取得學習數據
  const extWordData = useSelector((state) => state.wordData.wordsJSON);

  //設定預設畫面及選單內容
  React.useEffect(() => {
    //有預設Menu就以"Number"為預設畫面，無則使用"Welcome"作為預設畫面
    showDefaultMenuOpt
      ? dispatch(menuDataActions.setCurrPage("Number"))
      : dispatch(menuDataActions.setCurrPage("Welcome"));
  }, [dispatch, showDefaultMenuOpt]);

  //從外部取得學習數據
  React.useEffect(() => {
    //從Google Script取得Data, 結構為JSON
    const fetchWords = () => {
      axios({
        method: "get",
        url: "https://script.google.com/macros/s/AKfycbwt8q-ePNiRFIYcw4lUXOeL1YgVHpslJg8ChXnSxC477aJs1dn3r0_L__Zx2GhJS95rvA/exec",
      }).then((res) => {
        let extWordJson = parseExtWordData(res);
        dispatch(wordDataActions.saveFetchWords(extWordJson));

        //在Menu上添加選項
        let extMenuOpt = [];
        for (let key in extWordJson) {
          extMenuOpt.push(key);
        }
        dispatch(menuDataActions.setMenuOpt(extMenuOpt));
      });
    };

    var parseExtWordData = (extJSON) => {
      let resultData = {};
      let jsonData = extJSON.data;
      for (var key in jsonData) {
        if (isDev || jsonData[key].Valid === true) {
          let jsonKey = jsonData[key].Title;

          //let dataV1 = {};
          //dataV1[jsonKey] = jsonData[key].Words;

          let dataV2 = {};
          let subData1 = { Words: jsonData[key].Words };
          let subData2 = { CardType: jsonData[key].CardType };
          let subData3 = { ImgLinks: jsonData[key].ImgLinks };
          dataV2[jsonKey] = Object.assign(subData1, subData2, subData3);
          Object.assign(resultData, dataV2);
          //console.log(dataV2);
        }
      }
      return resultData;
    };

    //從Firestore取得Data, 結構為JSON
    const fetchWords_Firestore = async () => {
      const collRef = collection(db, "K3");
      //const qObj = query(collRef, where("Valid", "==", true));
      const qObj = query(collRef);
      let docs = null;
      await getDocs(qObj)
        .then((res) => {
          docs = res.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));
        })
        .catch((err) => console.log(err.message));

      //添加字庫
      let extWordJson = parseFirestoreData(docs);
      dispatch(wordDataActions.saveFetchWords(extWordJson));

      /*在Menu上添加選項*/
      let extMenuOpt = [];
      for (let key in extWordJson) {
        extMenuOpt.push(key);
      }
      dispatch(menuDataActions.setMenuOpt(extMenuOpt));
    };

    var parseFirestoreData = (jsonData) => {
      let resultData = {};

      for (var key in jsonData) {
        let id = jsonData[key].id;
        let { Valid } = jsonData[key].data;
        if (isDev || Valid) {
          let jsonKey = id;

          let dataV1 = {};
          dataV1[jsonKey] = Object.assign(jsonData[key].data);
          Object.assign(resultData, dataV1);
          //console.log(dataV1);
        }
      }
      return resultData;
    };

    if (ExtDataSrcType.Script === ExtDataSrc) fetchWords();
    if (ExtDataSrcType.Firestore === ExtDataSrc) fetchWords_Firestore();
  }, [dispatch]);

  //處理WordType改變
  React.useEffect(() => {
    let cardType = CardType.Default;
    let wordArray;
    let imgLinksArray;
    let dataArray = [];

    if (currMenuChoice === "Welcome") wordArray = Welcome.slice();
    else if (currMenuChoice === "Uppercase") wordArray = Uppercase.slice();
    else if (currMenuChoice === "Lowercase") wordArray = Lowercase.slice();
    else if (currMenuChoice === "Number") wordArray = Numbers.slice();
    else {
      cardType = extWordData[currMenuChoice].CardType;
      wordArray = extWordData[currMenuChoice].Data.slice();
      //console.log(wordArray);
    }

    if (cardType === CardType.TXT) {
      dispatch(menuDataActions.setShowImgBtn(false));
      dispatch(menuDataActions.setDisplayCardImg(false));
    }
    if (cardType === CardType.IMGTXT) {
      imgLinksArray = wordArray[0].ImgLink;
      if (imgLinksArray !== undefined) {
        dispatch(menuDataActions.setShowImgBtn(true));
        dispatch(menuDataActions.setDisplayCardImg(true));
      }
    }
    dispatch(wordDataActions.setCardType(cardType));

    //Combind Data
    for (let i = 0; i < wordArray.length; i++) {
      let res = {};
      Object.assign(res, { word: wordArray[i].Word });
      if (wordArray[i].ImgLink !== undefined) {
        Object.assign(res, { imgLink: wordArray[i].ImgLink });
      }
      //if (soundLinks........)
      dataArray.push(res);
    }

    if (rand) {
      dataArray = dataArray.sort(() => Math.random() - 0.5);
    }

    dispatch(wordDataActions.setDisplayWords(dataArray));

    return () => {};
  }, [dispatch, cardType, currMenuChoice, rand, extWordData]);

  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;

  const [orientation, setOrientation] = React.useState(
    isLandscape() ? "landscape" : "portrait"
  );

  /* 處理直向橫向問題 */
  React.useEffect(() => {
    //console.log("effect: " + orientation);
  }, [orientation]);

  React.useEffect(() => {
    window.onorientationchange = () => {
      sleep(50).then((r) => {
        let isLand = isLandscape() ? "landscape" : "portrait";
        setOrientation(isLand);
      });
    };
  });
  //sx={{ WebkitTransform: "rotate(0deg)" }}
  // return (
  //   <ThemeProvider theme={darkTheme}>
  //     <CssBaseline />
  //     <FABComp {...FABPara} />
  //     <CssBaseline />
  //     <CopyrightComp />
  //   </ThemeProvider>
  // );
  //position: absolute; left: 1px; top: 50%; -ms-transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);
  const rotateStyle = {
    WebkitTransform: "scale(0.9,0.9) rotate(90deg)" /* Safari and Chrome */,
    transform: "scale(1,) rotate(90deg)" /* Firefox, IE 9, Opera */,
    position: "absolute",
    left: "-50%",
    top: "25%",
  };

  return (
    <div style={orientation === "landscape" ? null : rotateStyle}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <MainArea>
          <FABComp />
          <CardSelector />
        </MainArea>
        <CssBaseline />
        {/*}
          <MainArea>
            <Typography
              style={{ WebkitWritingMode: "vertical-rl", fontSize: "6vh" }}
            >
              請橫置你的設備 <br />
              Please rotate your device
            </Typography>
          </MainArea>
          */}
        <CopyrightComp />
      </ThemeProvider>
    </div>
  );
}

export default App;
