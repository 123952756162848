/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  setDoc,
  doc,
  where,
  getDocs,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrsRtvafUxQbbIY0SfaxReuef8yzNqvxI",
  authDomain: "ezabc-4aec9.firebaseapp.com",
  projectId: "ezabc-4aec9",
  storageBucket: "ezabc-4aec9.appspot.com",
  messagingSenderId: "767606325074",
  appId: "1:767606325074:web:ffca324b53ea054386c48b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

//insert data to database
export const insertData = async () => {
  const collRef = collection(db, "K3");

  await setDoc(doc(collRef, "詞語-02"), {
    Lang: "Chinese",
    Title: "詞語-01",
    CardType: "TXT",
    Valid: true,
    Data: [
      { Word: "北京" },
      { Word: "上海" },
      { Word: "珠海" },
      { Word: "故宮" },
      { Word: "天壇" },
      { Word: "天安門" },
      { Word: "澳門" },
      { Word: "中國" },
      { Word: "香港" },
      { Word: "國家" },
      { Word: "麵粉娃娃" },
      { Word: "烤鴨" },
      { Word: "泡茶" },
      { Word: "面譜" },
      { Word: "剪紙" },
      { Word: "中秋節" },
      { Word: "好吃的" },
      { Word: "拉麵" },
      { Word: "萬里長城" },
      { Word: "最美麗的" },
      { Word: "最有趣的" },
      { Word: "美國" },
      { Word: "國旗" },
      { Word: "鳥巢" },
      { Word: "水立方" },
      { Word: "漂亮的" },
      { Word: "最珍貴的" },
    ],
  });

  /* English
  await setDoc(doc(collRef, "Eng-Topic01"), {
    Lang: "English",
    Title: "Topic01",
    CardType: "IMGTXT",
    Valid: true,
    Data: [
      {
        Word: "teacher",
        ImgLink: "https://upload.cc/i1/2022/09/07/CeFsWQ.png",
      },
      {
        Word: "pencil",
        ImgLink: "https://upload.cc/i1/2022/09/07/zb68DK.png",
      },
      { Word: "eraser", ImgLink: "https://upload.cc/i1/2022/09/07/QWGOSJ.png" },
      {
        Word: "pen",
        ImgLink: "https://upload.cc/i1/2022/09/07/y0MZxE.png",
      },
      { Word: "book", ImgLink: "https://upload.cc/i1/2022/09/07/41iXzm.png" },
      { Word: "ruler", ImgLink: "https://upload.cc/i1/2022/09/07/ciLNIy.png" },
      { Word: "bag", ImgLink: "https://upload.cc/i1/2022/09/07/XVIspl.png" },
      {
        Word: "crayons",
        ImgLink: "https://upload.cc/i1/2022/09/07/nEHZw1.png",
      },
      {
        Word: "glue",
        ImgLink: "https://upload.cc/i1/2022/09/07/O5qRot.png",
      },
      {
        Word: "pencil case",
        ImgLink: "https://upload.cc/i1/2022/09/08/3lwNVo.png",
      },
      {
        Word: "scissors",
        ImgLink: "https://upload.cc/i1/2022/09/08/ag4MTw.png",
      },
    ],
  });
  */
};

//insertData();

export const renameDocId = async (colName, beforeId, afterId) => {
  const docRef = doc(db, colName, beforeId);
  await getDoc(docRef).then(async (res) => {
    console.log(res.id);
    const collRef = collection(db, colName);
    await setDoc(doc(collRef, afterId), res.data());
    await deleteDoc(docRef);
  });
};

//renameDocId("K3", "Eng-Topic01", "Eng-Topic99");
