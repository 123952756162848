/*
 * @Author       : Derek.K
 * @Date         : 2022-02-22 09:44:06
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-22 09:44:06
 * @Description  : N/A
 */
export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
