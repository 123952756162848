import { createSlice } from "@reduxjs/toolkit";

const defaultMenuOpt = ["Number", "Lowercase", "Uppercase"];

const initialmenuDataState = {
  showDefaultMenuOpt: false,
  showMenu: true,
  currPage: "Welcome",
  //wordDataArray: [],
  showRandBtn: true,
  rand: false,
  showImgBtn: false,
  displayCardImage: false,
  menuOpt: defaultMenuOpt,
};

const menuDataSlice = createSlice({
  name: "menuData",
  initialState: initialmenuDataState,
  reducers: {
    setCurrPage(state, action) {
      state.currPage = action.payload;
    },
    setRandam(state, action) {
      state.rand = action.payload;
    },
    toggleRandam(state) {
      state.rand = !state.rand;
    },
    setShowImgBtn(state, action) {
      state.showImgBtn = action.payload;
    },
    toggleShowImgBtn(state) {
      state.showImgBtn = !state.showImgBtn;
    },
    setDisplayCardImg(state, action) {
      state.displayCardImage = action.payload;
    },
    toggleDisplayCardImg(state) {
      state.displayCardImage = !state.displayCardImage;
    },
    setMenuOpt(state, action) {
      //state.menuOpt = defaultMenuOpt.concat(action.payload);
      state.menuOpt = state.showDefaultMenuOpt
        ? defaultMenuOpt.concat(action.payload)
        : action.payload;
    },
  },
});

export const menuDataActions = menuDataSlice.actions;

export default menuDataSlice.reducer;
