/*
 * @Author       : Derek.K
 * @Date         : 2021-11-12 14:36:47
 * @LastEditors  : Derek.K
 * @LastEditTime : 2022-02-21 10:46:45
 * @Description  : N/A
 */
import React from "react";
import { useSelector } from "react-redux";

import CardTxtComp from "./CardTextComp";
import CardPhotoComp from "./CardPhotoComp";
import { CardType } from "../utils/EnumData";

export default function CardSelector() {
  const cardType = useSelector((state) => state.wordData.cardType);
  const displayCardImage = useSelector(
    (state) => state.menuData.displayCardImage
  );

  return (
    <>
      {cardType === CardType.TXT ? <CardTxtComp /> : <></>}
      {cardType === CardType.IMGTXT ? (
        displayCardImage ? (
          <CardPhotoComp />
        ) : (
          <CardTxtComp />
        )
      ) : (
        <></>
      )}
    </>
  );
}
